
img { max-width: 100%; height: auto; } 
*, :before, :after {box-sizing: border-box;}


.page-wrpper { display: flex;  max-width: 100%; margin: auto; min-height: 100vh; } 
.content-wrapper { width: calc(100% - 110px); padding: 0 0 0 0px; margin: 0; box-shadow: 0 20px 50px 0px #0c0c0c26 inset; background: #fdfdfd; } 
.header-area { background: #0051A6; min-height: 180px; border-radius: 0 0 50px 50px; padding: 15px 5% 80px; display: flex; width: 100%; flex-wrap: wrap; } 


.header-top { display: flex; padding: 0 0 1em; width: 100%; justify-content: space-between; } 
.header-top button {  border: 0; background: none; color: #fff; padding: 0;cursor: pointer;} 
.header-top  .left-content .wrapper{ width: auto; background: #0064af; display: inline-block; padding: 0; border-radius: 100px; overflow: hidden;position: relative;z-index: 1; }
.header-top .left-content .wrapper:before { content: ''; width: 50%; height: 100%; position: absolute; z-index: -1; background: #fff; border-radius: 100px; left: 0; transition: all 0.2s ease 0s; } 
.header-top .left-content button:first-child { color: rgba(0, 81, 166, 1); } 
.header-top .left-content .wrapper[status='active']:before { left: 50%; } 
.header-top .left-content .wrapper[status='active'] button:first-child { color: #fff; } 
.header-top .left-content .wrapper[status='active'] button:last-child { color: rgba(0, 81, 166, 1); } 
.header-top  .left-content button { width: 125px; padding: 10px 10px; }
.header-top  .right-content button { padding: 0 10px; }
.header-top .right-content img { max-width: 32px; border-radius: 100px; margin: 0 5px 0 0; height: 32px;} 
.header-top .right-content { display: flex; align-items: center; height: 36px; }

.header-left {width: calc(100% - 300px);} 
.header-right { width: 300px; display: flex; align-items: center;justify-content: flex-end;} 
.filter.item { display: inline-block; position: relative;z-index: 999999; } 
.filter-title { background: #fff; border-radius: 100px; display: inline-block; padding: 10px 20px; color: #7D7D7D;cursor: pointer; } 
.filter-title svg.tabler-icon { color: #0051A6; margin: 0 0 0 6px; } 
.dropdown-filters { position: absolute; background: #fff; z-index: 99; width: 200px; right: 0; top: 54px; border-radius: 10px; border: 1px solid #919eab40;display: none; } 
.dropdown-filters:before { content: ''; display: block; position: absolute; right: 19px; top: -13px; border-width: 0 9px 13px; border-style: solid; border-color: transparent transparent #fff; }
.mantine-Grid-root { margin: 0; }
.dropdown-item:not(:last-child) { border-bottom: 1px solid rgba(145, 158, 171, 0.32); } 
.dropdown-item .collapsible-filter-title { padding: 10px 15px; position: relative;cursor: pointer; } 
.dropdown-item  .collapsible { display: none; }
.dropdown-item  .collapsible .mantine-Checkbox-root{ border-bottom: 1px solid rgba(145, 158, 171, 0.32);padding: 10px 15px; }
.dropdown-item  .collapsible .mantine-Checkbox-root:last-child{border-bottom: 0 none;}
.filter-title[status='current'] + .dropdown-filters {display: block;}

.datefilter input[type="text"] { border: 0; outline: none !important; z-index: 2; position: relative; background: transparent; width: 100px; } 
.datefilter .filter-title { position: relative; } 
.datefilter svg.tabler-icon { position: absolute; right: 15px; top: 10px; } 
.filter.item:not(:last-child) { margin: 0 20px 0 0; }
.datefilter .react-datepicker__close-icon { right: -9px; z-index: 3; background: #fff; }
.datefilter .react-datepicker__close-icon::after {background: red;font-size: 18px;line-height: 11px;}
.collapsible-filter-title[status*='current'] + .collapsible {display: block;}

.dropdown-item .collapsible-filter-title:before { content: ""; width: 8px; height: 8px; border-width: 0 0 1px 1px; border-style: solid; display: block; position: absolute; transform: rotate(-45deg); right: 17px; top: 16px; } 
.dropdown-item .collapsible-filter-title[status='current']:before { transform: rotate(135deg); top: 21px; }

.main-content { padding: 0 15px 0 5%; max-width: 1800px; margin: auto;} 

.main-content .mantine-ScrollArea-root { margin: -4em  0 2em; position: relative; } 
tr.mantine-1avyp1d { margin: 0 0 20px; display: flex; padding: 15px 10px; border-radius: 10px; border: 0 none; box-shadow: 0px 68px 41px rgba(0, 0, 0, 0.01), 0px 30px 30px rgba(0, 0, 0, 0.02), 0px 8px 17px rgba(0, 0, 0, 0.02), 0px 0px 0px rgba(0, 0, 0, 0.02); border-left: 12px solid #FF6100; } 
table.mantine-Table-root { border-collapse: collapse; overflow: visible; } 
.mantine-a5zxts { overflow: visible; } 
.mantine-ScrollArea-root { overflow: visible; } 
.mantine-b6zkvl.mantine-ScrollArea-viewport { border-radius: 10px; } 
tr.mantine-1avyp1d:nth-child(odd) { border-color: #00DCA7; }
.mantine-a5zxts.mantine-a5zxts tbody tr td { border: 0;overflow: hidden; text-overflow: ellipsis;font-weight: 500; }
.mantine-Table-root td:before { content: ''; width: 100%; color: #7D7D7D; font-size: 12px; display: inline-block; font-weight: 400; padding: 0 0 4px; } 
.mantine-Table-root td.first-name:before { content: 'First Name'; } 
.mantine-Table-root td.last-name:before { content: 'Last Name'; } 
.mantine-Table-root td.email:before { content: 'Email'; } 
.mantine-Table-root td.gender:before { content: 'Gender'; } 
.mantine-Table-root td.dob:before { content: 'D.o.B.'; } 
.mantine-Table-root td.glucose-level:before { content: 'Fasting Glucose - @7am'; } 
.mantine-Table-root td.goal:before { content: 'Goal'; } 
.mantine-Table-root td.height:before { content: 'Height'; } 
.mantine-Table-root td.weight:before { content: 'Weight'; } 
.mantine-Table-root td.mobile:before { content: 'Contact Number'; } 
.mantine-Table-root td.onboarding:before { content: 'Onboarded On'; } 
.mantine-Table-root td.device:before { content: 'Device'; } 
.mantine-Table-root td.last-active:before { content: 'Last Active'; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.height:after { content: ' CMS'; font-weight: 400; font-size: 12px; color: #7D7D7D; } 
.mantine-Table-root td:after { color: #7d7d7d; font-size: 12px; } 
.mantine-Table-root td.weight:after { content: 'Ibs'; content: "  Ibs"; }

.mantine-a5zxts.mantine-a5zxts tbody tr td.msg-unread { overflow: visible; position: absolute; right: 120px; z-index: 999; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.msg-unread .hidden { display: none; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.msg-unread .visible svg { color: #898989; }
.mantine-a5zxts.mantine-a5zxts tbody tr td.msg-unread .visible span.count { position: absolute; left: 8px; top: -14px; background: #ff0000; min-width: 20px; height: 20px; display: flex; justify-content: center; align-items: center; border-radius: 25px; color: #ffff; padding: 0 4px; font-size: 12px; } 
.mantine-a5zxts.mantine-a5zxts tbody tr td.msg-unread .visible { position: relative; }

.Dashboard input.mantine-Input-input { border-radius: 25px; border: 0 none; box-shadow: none; height: 40px; }
.mantine-Grid-col { padding-left: 0; }


.user-link a { position: absolute; right: 20px; top: 0; bottom: 0; width: 55px; height: 55px; border: 1px solid #00dca770; border-radius: 100px; margin: auto; display: flex; justify-content: center; align-items: center; color: #00DCA7; transition: all 0.1s ease-in 0s; box-shadow: 0px 0px 0px -2px #00403138; background: #fff; } 
.user-link a:hover { color: #fff; background: #00DCA7; border-color: #00DCA7; right: 15px; box-shadow: -25px 0px 15px 0 #00403138; }
.cta-wrapper { position: absolute; z-index: 99; background: #fff; height: 75px; padding: 25px; right: 1px; min-width: 100px; }
.mantine-a5zxts.mantine-a5zxts tbody tr td.user-link {display: flex;align-items: center;}

h1 { color: #fff; font-weight: 600; margin: 0 0 0.6em; } 

.header-top .left-content button[value='Appointments'] { background: #FF6100; border-radius: 9px; vertical-align: top; margin: 0 0 0 20px; padding: 10px 45px; width: auto; letter-spacing: 0.4pt; font-size: 14px; } 
.fc.fc-media-screen.fc-theme-standard { margin: -4em 0 2em 0; background: #fff; padding: 10px 0px; border-radius: 15px; box-shadow: 0 0 15px rgba(0,0,0,0.1); } 
.fc-header-toolbar.fc-toolbar { padding: 0 20px; justify-content: flex-start; } 
.fc .fc-toolbar-title { font-size: 1.2em; margin: 0 40px 0 0; } 
.fc-theme-standard td, .fc-theme-standard th { border-color: rgba(145, 158, 171, 0.32); } 
.fc .fc-scrollgrid table { border: 0 none; } 
.fc-theme-standard .fc-scrollgrid { border: 0; } 
.fc-theme-standard td:first-child, .fc-theme-standard th:first-child { border-left: 0; } 
.fc-theme-standard td:last-child, .fc-theme-standard th:last-child { border-right: 0; } 
.fc-theme-standard th { border-bottom: 0; } 
.fc-theme-standard td { border-top: 0; } 
.fc .fc-col-header-cell-cushion { color: #9A9A9A; font-weight: 600; padding: 10px 0 0; text-transform: uppercase; } 
.fc .fc-daygrid-day-top { justify-content: center; padding: 10px 0 0; font-size: 600; } 
.fc .fc-daygrid-day-number { font-weight: 600; } 
.fc .fc-button-primary { background: none !important; color: #000 !important; border: 1px solid rgba(145, 158, 171, 0.32) !important; text-transform: capitalize; border-radius: 7px; font-weight: 600; padding: 6px 20px; box-shadow: none !important;} 
.fc .fc-button-group > .fc-button { border: 0 !important; padding: 5px 7px !important; color: #0051A6 !important; }
.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start { justify-content: center; cursor: pointer; } 
.fc-direction-ltr .fc-daygrid-event.fc-event-end div { flex: 0 auto; } 
.fc-daygrid-event-dot { border-color: #ff6100; } 
.fc-daygrid-dot-event .fc-event-title { font-weight: 500; letter-spacing: 0.4pt; text-overflow: ellipsis; white-space: pre; }


.popup { position: fixed; z-index: 101; width: 450px; height: 230px; background: #fff; top: 0; bottom: 0; left: 0; right: 0; margin: auto; padding: 10px 20px 20px; max-width: 95%; border-radius: 12px; box-shadow: 0 0 100px rgba(0,0,0,0.2); } 
.poup-bg { position: fixed; z-index: 100; background: #000; width: 100%; height: 100%; left: 0; top: 0; opacity: 0.2; } 
.popup .popup-titlebar { position: relative; display: flex; border-bottom: 1px solid rgba(145, 158, 171, 0.2); padding: 5px 0 12px; margin: 0 0 12px; color: #000; font-size: 15px; font-weight: 500; } 
.popup .popup-titlebar .time { padding: 0 0 0 10px; margin: 0 0 0 10px; border-left: 1px solid rgba(145, 158, 171, 0.2); } 
.popup .subject { padding: 0 0 15px; margin: 0 0 15px; border-bottom: 1px solid rgba(145, 158, 171, 0.2); } 
.popup .subject span.label { font-size: 12px; color: #6a6a6a; letter-spacing: 0.2pt; } 
.popup .subject .sub-text { display: inline-block; width: 100%; padding: 5px 0 0; font-weight: 500; letter-spacing: 0.2pt; } 
.popup .meeting-link { display: inline-block; width: 100%; } 
.popup .meeting-link span.label { font-size: 14px; color: #000; letter-spacing: 0.2pt; font-weight: 500; } 
.popup .meeting-link a.link-text { display: block; width: 100%; color: #2A3DE8; font-weight: 500; padding: 5px 0 0; font-size: 14px; }
.popup span.close {z-index: 1; position: absolute; right: -10px; top: 0; width: 30px; height: 30px; cursor: pointer; } 
.popup span.close:before, .popup span.close:after {z-index: -1; content: ''; width: 18px; height: 2px; background: #000; position: absolute; top: 0; left: 0; transform: rotate(45deg); bottom: 0; margin: auto; right: 0; } 
.popup span.close:after { transform: rotate(-45deg); }


.pager button.mantine-UnstyledButton-root[data-hidden='disabled'] { display: none;pointer-events: none; } 
.pager button.mantine-UnstyledButton-root[data-disbaled='disabled'] { cursor: default; opacity: 0.7; color: #999;pointer-events: none; } 
.pager button.mantine-UnstyledButton-root { cursor: pointer; margin: 0 2px 0; border: 1px solid #e0e0e0; background: #fff; display: inline-flex; height: 28px; width: 28px; align-items: center; justify-content: center; border-radius: 5px; } 
.pager .mantine-Group-root.mantine-kn8vaf { display: flex; align-items: flex-start; padding: 0 10px 20px; justify-content: flex-end; } 
.pager button.mantine-UnstyledButton-root.active { background: #0063a7; color: #fff; border-color: #0063a7; }

@media screen and (max-width:991px) {
    
    .content-wrapper { width: calc(100% - 76px); } 
    .header-area { padding: 30px 15px 80px; } 
    .main-content { padding: 0 15px; }
    .mantine-Grid-col.mantine-upafdc { flex-basis: 100%; max-width: 100%; margin: 0; padding: 0; }
}
@media screen and (max-width:768px) {
.header-left { width: 100%; padding: 0 0 10px; } 
.header-right { width: 100%; } 
.filter.item { padding: 0; } 
.filter.item:not(:last-child) { margin: 0 5px 0 0; }
}